<template>
  <nav>
    <div class="gnb-wrap">
      <div class="gnb-inner">
        <ul class="gnb1">
          <!--<li @mouseenter="enter" @mouseleave="leave">
            <router-link class="anime2" to="/">Home</router-link>
          </li>-->
          <li>
            <router-link
              class="anime2"
              to="/magazine"
              @click.native="$emit('update:navActive', false)"
              >Magazine</router-link
            >
          </li>
          <li>
            <a class="anime2" href="https://www.facebook.com/emotion.co.kr/" target="_blank"
              >Facebook</a
            >
          </li>
        </ul>
        <ul class="gnb2">
          <li>
            <router-link
              to="/works"
              @click.native="$emit('update:navActive', false)"
              class="anime1"
            >
              Works
            </router-link>
          </li>
          <li>
            <router-link
              to="/about"
              @click.native="$emit('update:navActive', false)"
              class="anime1"
            >
              About
            </router-link>
          </li>
          <li>
            <router-link
              to="/careers"
              @click.native="$emit('update:navActive', false)"
              class="anime1"
            >
              Careers
            </router-link>
          </li>
          <li>
            <router-link
              to="/request"
              @click.native="$emit('update:navActive', false)"
              class="anime1"
            >
              Request
            </router-link>
          </li>
        </ul>
        <div class="logo">
          <div class="dot">
            <img :src="require('@/assets/images/@temp/nav_logo.png')" alt="" />
            <!--<span class="t">C</span>
            <span class="l">C</span>
            <span class="r">C</span>
            <span class="b">C</span>-->
          </div>
          <strong>MAKE · GREATNESS</strong>
          <span>위대함을 만들어 갑니다. </span>
        </div>
      </div>
    </div>
    <div class="bg"></div>
  </nav>
</template>
<script>
  export default {
    name: "AppNavigation",
    data() {
      return {};
    },
    methods: {
      // enter(e) {
      //   //e.target.style.overflow = "visible";
      // },
      // leave(e) {
      //   /*setTimeout(() => {
      //     e.target.style.overflow = "hidden";
      //   }, 300);*/
      // }
    },
    mounted() {},
    destroyed() {}
  };
</script>
<style lang="scss" scoped>
  nav {
    z-index: 1111;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    ::v-deep.scroll-content {
      height: 100%;
      width: 100%;
    }
    .gnb-wrap {
      @include globalWidth();
      z-index: 2;
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      min-height: 800px;
    }
    .gnb-inner {
      position: relative;
      width: 100%;
    }

    .bg {
      position: absolute;
      bottom: 100%;
      left: 0;
      height: 100%;
      width: 100%;
      min-height: 800px;
      min-width: $globalWidth;
      background: $red;
    }
    .gnb1 {
      display: flex;
      justify-content: flex-end;
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      @include montserrat();
      /*li:first-child {
        margin-left: 0;
        margin-right: auto;
      }*/
      li {
        overflow: hidden;
        margin-left: 120px;
      }
      a {
        display: inline-block;
        color: #fff;
        transform: translateY(-110%);
      }
    }

    .gnb2 {
      margin-top: 85px;
      font-weight: bold;
      font-size: 80px;
      line-height: 80px;

      @include montserrat();
      li {
        margin-top: 55px;
        overflow: hidden;
      }
      li:first-child {
        margin-top: 0;
      }
      a {
        display: inline-block;
        color: #fff;
        transform: translateY(110%);
      }
    }

    .logo {
      opacity: 0;
      position: absolute;
      top: 350px;
      left: 870px;
      width: 143px;
      color: #fff;
      line-height: 24px;
      white-space: nowrap;
      @include montserrat();
      .dot {
        position: relative;
        padding-top: 100%;

        img {
          position: absolute;
          top: 0;
          left: 0;
        }
        /*&:before {
        z-index: 2;
        background: #fff;
        border-radius: 50%;
      }
      &:before,
      span {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -10px 0 0 -10px;
        font-weight: bold;
        font-size: 30px;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-indent: -3px;
        background: #0ff;
        border-radius: 50%;
        &.t {
          transform: tra;
        }
      }*/
      }
      > strong {
        display: block;
        margin-top: 60px;
        font-weight: bold;
        font-size: 14px;
      }
      > span {
        font-size: 13px;
        font-family: "Noto Sans KR", Montserrat, sans-serif;
        color: #fb8992;
      }
    }
  }
</style>
